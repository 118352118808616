<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="settings-parkingspace">
  <div class="box pscontainer">
    <div class="header">
      <div class="text">{{$t('common.parking_space')}} - {{$t('common.parking_sensor')}} {{$t('common.assignment')}}</div>
      <div class="pushright"></div>
      <div v-if="!parkingsensors.editMode && allow.pss_update && hasSelectedPs()" @click="startEditing" class="btn"><i class="icon icon-edit"></i></div>
      <div v-if="parkingsensors.editMode && Object.keys(temp).length" @click="submitEditing" class="btn"><i class="icon icon-check"></i></div>
      <div v-if="parkingsensors.editMode" @click="finishEditing" class="btn"><i class="icon icon-cross"></i></div>
    </div>
    <table>
      <thead>
        <tr>
          <th>{{$t('settings.binding.name')}}</th>
          <th>{{$t('common.parking_sensor')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="d in selectedpss" :key="d.id">
          <td style="padding-left: 20px;">{{d.name}}</td>
          <td style="width: 50%">
            <div v-if="!parkingsensors.editMode || temp[d.id]" class="sensor-name">
              {{getSensorName(d)}}
              <button v-if="temp[d.id]" @click="submitChange(d)" class="btn"><i class="icon icon-check"></i></button>
              <button v-if="temp[d.id]" @click="cancelChange(d)" class="btn"><i class="icon icon-cross"></i></button>
            </div>
            <v-select v-if="parkingsensors.editMode && !temp[d.id]" style="width: 100%" @input="handleSelectChange(d, $event)" 
              :value="d.parking_sensor" :options="Array.from(parkingsensors.unassigned)" label='name' :clearable="false"
              ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="less">
@import '/global';

.settings-parkingspace{
  &>.box>.header>.text{
    font-size: 18px;
  }

  table{
    padding: 15px;
    width: 100%;

    th{
      text-align: left;
    }

    thead::after{
      content: "";
      display: block;
      height: 10px;
    }

    .v-select .dropdown-toggle{
      background-color: #fff;
      border-radius: @border-radius-input;
    }
  }

  .sensor-name {
    line-height: 34px;

    button {
      border: none;
      height: 30px;
      line-height: 30px;
      margin-left: 10px;
      padding: 0 5px;
    }
  }
}
</style>

<script>

import m from '/model';
import VSelect from 'vue-select';

export default{
  name: 'settings-parkingspace',
  components: {VSelect},
  props: { selectedpss: Array },
  data(){
    return{
      parkingsensors: m.parkingsensors,
      parkingspaces: m.parkingspaces,
      allow: m.user.allow,
      temp: {},
    };
  },
  destroyed() {
    if (m.parkingsensors.editMode) {
      m.parkingsensors.setEditMode(false);
    }
  },
  methods: {
    hasSelectedPs() {
      return this.selectedpss.length > 0;
    },
    getSensorName(d) {
      return m.parkingsensors.getById(this.temp[d.id] ? this.temp[d.id].id : d.parking_sensor.id).name;
    },
    startEditing() {
      m.parkingsensors.setEditMode(true);
    },
    finishEditing() {
      m.parkingsensors.setEditMode(false);
      this.temp = {};
    },
    submitEditing() {
      Object.keys(this.temp).map(id => m.parkingspaces.getById(id)).forEach(d => {
        this.submitChange(d);
      });
      this.finishEditing();
    },
    handleSelectChange(d, sensor) {
      this.temp[d.id] = sensor;
      this.$forceUpdate();
    },
    submitChange: async function(d) {
      const res = await m.parkingspaces.setSensor(d, this.temp[d.id]);
      if (res) {
        this.cancelChange(d);
      }
    },
    cancelChange(d) {
      delete this.temp[d.id];
      this.$forceUpdate();
    },
  }
};
</script>
