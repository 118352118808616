<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="groupalertrule box">
  <div class="header" v-if="working && r">
    <div class="text">{{r.id}}</div>
    <div class="pushright"></div>
    <spinner></spinner>
  </div>
  <div class="header" v-if="!working">
    <div v-if="!edit" class="text">{{r.name}}</div>
    <input type="text" v-if="edit" v-model="newvals.name" :class="{error: errors.name}">
    <div class="pushright"></div>
    <div v-if="edit" @click="cancelEditing" class="btn"><i class="icon icon-cross"></i></div>
    <div v-if="edit" @click="finishEditing" class="btn"><i class="icon icon-check"></i></div>
    <div v-if="allow.gar_update && !edit && !deleting" @click="startEditing" class="btn"><i class="icon icon-edit"></i></div>
    <div v-if="deleting" @click="deleteThis()" class="btn"><i class="icon icon-trashcan"></i></div>
    <div v-if="deleting" @click="deleting=false" class="btn"><i class="icon icon-cross"></i></div>
    <div v-if="allow.gar_delete && !deleting && !adding && !edit" @click="deleting=true" class="btn"><i class="icon icon-trashcan"></i></div>
  </div>
  <div class="edit">
    <div>{{$t('settings.gar.enabled')}}</div>
    <div v-if="!edit">{{$t('common.'+r.enabled)}}</div>
    <div v-if="edit"><input type="checkbox" v-model="newvals.enabled" :class="{error: errors.enabled}" :disabled="working"></div>


    <div>{{$t('common.parking_space_group')}}</div>
    <div v-if="!edit">{{r.group ? r.group.name : ''}}</div>
    <div v-if="edit">
      <v-select :options="group_list" :get-option-label="pp=>pp.name" label="name" v-model="newvals.group" 
        :clearable="false" :class="{error: errors.group}" :disabled="working"
        ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select>
    </div>

    <div>{{$t('settings.gar.rule_eval_method')}}</div>
    <div v-if="!edit">{{$t('gartypes.'+r.rule_eval_method)}}</div>
    <div v-if="edit"><v-select :clearable="false" :options="Object.keys($t('gartypes'))" :get-option-label="i=>$t('gartypes.'+i)"
      v-model="newvals.rule_eval_method" :class="{error: errors.rule_eval_method}" :disabled="working"
      ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select></div>

    <div v-if="(!edit && r.rule_eval_method == 'DRI') || (edit && newvals.rule_eval_method == 'DRI')">
      {{$t('settings.gar.sum_interval_minutes')}}
    </div>
    <div v-if="!edit && r.rule_eval_method == 'DRI'">{{r.sum_interval_minutes}} {{$t('stats.histranges.i')}}</div>
    <div v-if="newvals.rule_eval_method == 'DRI' && edit">
      <v-select :options="sum_interval_list" label="name" v-model="newvals.sum_interval_minutes" 
        :clearable="false" :class="{error: errors.sum_interval_minutes}" class="dimension-edit" :disabled="working"
        ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select><span class="dimension-info">{{$t('stats.histranges.i')}}</span>
    </div>


    <div>{{$t('settings.gar.threshold_percent')}}
      <div class="explainer">{{$t('settings.gar.threshold_explain')}}</div>
    </div>
    <div v-if="!edit">{{r.threshold_percent}}{{$t('settings.gar.percent')}}</div>
    <div v-if="edit"><input type="number" :class="{error: errors.threshold_percent}" v-model="newvals.threshold_percent" :disabled="working" min="1" max="100" class="dimension-edit"><span class="dimension-info">%</span></div>

    <div>
      {{$t('settings.gar.recipients')}}
      <div class="explainer">{{$t('settings.gar.recipients_explain')}}</div>
    </div>
    <div v-if="!edit">
      <span v-for="rc in r.recipients" v-bind:key="rc.id" class="v-select v-readonly">
        <span class="selected-tag">{{rc.user_name}} – {{$t('settings.gar.accepts_email')}} <input type="checkbox" class="v-internal-checkbox" v-model="rc.accepts_email" disabled="true"></span>
      </span>
    </div>
    <div v-if="edit"><v-select multiple :options="possible_recipients" label="user_name" v-model="newvals.recipients" 
      :disabled="working" :class="{error: errors.recipients}">
        <template slot="no-options">{{$t('common.selection_empty')}}</template>
        <template slot="selected-option" slot-scope="option">
          <label style="white-space:nowrap">{{option.user_name}} – {{$t('settings.gar.accepts_email')}}
              <input v-if="option.id" type="checkbox" class="v-internal-checkbox" v-model="(r.recipients[r.recipients.findIndex(orc=>orc.user===option.user)] || option).accepts_email" :disabled="working" :readonly="!edit">
            </label>
        </template>
      </v-select></div>

  </div>
</div>
</template>

<style lang="less">
@import '/global';

.groupalertrule{
  position: relative;
  margin: 15px;

  .v-readonly {
      .selected-tag {
        display:inline-block;
      }
    }

  .error {
      outline: 2px solid red;
      outline-offset: -2px;
  }

  .header{
    input{
      width: 140px;
      padding: 0 5px;
    }
  }

  .dimension-edit {
    display: inline-block;
    width: 35% !important;
  }
  .dimension-info {
    display: inline-block;
    padding-left: 5%;
    width: 30%;
  }
  .explainer {
    margin-top: 4px;
    font-size: 70%;
  }

  .edit{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 15px 15px 30px 40px;

    input[type="checkbox"].v-internal-checkbox {
      margin-bottom:-4px;
      margin-left: 4px;
      margin-right: 4px;
    }
    .v-text-in-option {
      margin-bottom:-4px;
      color: #888;
    }

  }
  .v-select .dropdown-toggle{
    background-color: #fff;
    border-radius: @border-radius-input;
  }
}
</style>

<script>
import VSelect from 'vue-select';
import m from '/model';
import Spinner from './spinner';


// v-model="r.recipients[r.recipients.findIndex(orc=>orc.user===option.user)].accepts_email"

const validators = {
  name: i=>(i.length > 0),
  enabled: i=>Number(i)>=0 && Number(i)<=1, // Boolean
  group: i=>(typeof i !== 'undefined'),
  recipients: i=>(Array.isArray(i) /*&& i.length > 0*/),
  rule_eval_method: i=>i != null && i.match(/^(DRI|SLA)$/) != null, // HACK: Uses constants
  sum_interval_minutes: i=>Number(i)>=1 && Number(i)<=1440,
  threshold_percent: i=>Number(i)>=1 && Number(i)<=100,
};
let validatorsStates = {};
Object.keys(validators).map(function(key) { validatorsStates[key] = false });

export default {
  name: 'groupalertrule',
  props: {
    r: Object,
    adding: {
      type: Boolean,
      default: false
    }
  },
  components: { Spinner, VSelect},
  data(){
    return {
      // CRUD control
      working: false,
      allow: m.user.allow,
      edit: this.adding || false,
      deleting: false,
      // References
      group_list: m.groups.list,
      // Empty object template
      newvals: {
        // General settings
        name: '',
        enabled: true,
        group: m.groups.list.length > 0 ? m.groups.list[0] : undefined,
        recipients: [],
        // Execution model
        rule_eval_method: 'DRI',
        sum_interval_minutes: 15,
        threshold_percent: 20,
        // Feedback
        last_alert_at: null,
      },
      errors: validatorsStates,
    };
  },
  computed: {
    possible_recipients() {
       return m.groupalertrules.possibleRecipients.map(rc=>({
         user: rc.id,
         user_name: rc.name,
         accepts_email: true,
        }));
    },
    sum_interval_list() {
      // let items = [];
      // for (let m in [5,10,15,20,30,45,60,120,240]) {
      //   m = 1;
      // }
      return [5, 10, 15, 20, 30, 45, 60, 120, 240];
    },
    readOnly() {
      return !(!this.working && this.edit);
    }
  },
  methods: {
    startEditing(){
      if(!this.adding){
        // General
        this.newvals.name = this.r.name;
        this.newvals.group = this.r.group || m.groups.list[0];
        this.newvals.enabled = this.r.enabled;
        this.newvals.recipients = this.r.recipients; // TODO: Add recpt handling
        // Exec
        this.newvals.rule_eval_method = this.r.rule_eval_method;
        this.newvals.sum_interval_minutes = parseInt(this.r.sum_interval_minutes);
        this.newvals.threshold_percent = this.r.threshold_percent;
        // Feedback
        this.newvals.last_alert_at = this.r.last_alert_at;
        this.edit = true;
      }
    },

    // General operations on dataset
    cancelEditing(){
      this.$emit('finished');
      this.edit = this.adding;
    },
    finishEditing: async function(){
      var err = false;
      for(var k in validators){
        if(!validators[k](this.newvals[k], this.newvals)){
          this.errors[k] = true;
          err = true;
        }else{
          this.errors[k] = false;
        }
      }
      if(err) return;

      this.working = true;
      //throw 'testerror';
      const newvals = Object.assign({}, this.newvals);
      let res;
      if(!this.adding){
        res = await m.groupalertrules.update(this.r, newvals);
      }else{
        res = await m.groupalertrules.add(newvals);
      }
      this.working = false;
      if (res) {
        this.$emit('finished');
        this.edit = this.adding;
      }
    },
    deleteThis: async function(){
      const res = await m.groupalertrules.delete(this.r);
      this.deleting = !res;
    },
  }
};
</script>
