<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div id="stats">
  <div class="r-c">
    <div class="debug-query" style="display:none">
      <div><input type="checkbox" id="altds" value="1" v-model="use_v2_api"><label for="altds">Use Go webservice for query data source</label></div>
    </div>
    <div class="timeranges">
      <span class="button-color input-style" v-for="r in qtime.list" @click="qtime.loadByName(r)">{{$t('stats.timeranges.'+r)}}</span>
    </div>
    <div class="timerange">
      <div class="container">
        <datetimepicker v-model="qtime.start" :limittoday="true" :class="{ error: qtime.start >= qtime.end, 'flatpickr-input': true }" 
        ></datetimepicker>
      </div>
      <div class="container">
        <datetimepicker v-model="qtime.end" :limittoday="true" :class="{ error: qtime.start >= qtime.end, 'flatpickr-input': true }" 
        ></datetimepicker>
      </div>
      <v-select :options="qtime.resolutions" :get-option-label="o=>$t('stats.dividers.'+o)" v-model="qtime.res" :clearable="false" :searchable="false"></v-select>
      <span class="btn" @click="runFT()"><i class="icon icon-check"></i></span>
    </div>
    <div class="charts">
      <chart v-for="c in charts" :chart="c"></chart>
      <histogram></histogram>
    </div>
    <parking-history v-if="queryInfo.phHasData"></parking-history>
    <div class="r-b">
      <div :class="{export: true, btn: true, disabled: !canDownloadParkings}"
        @click="exportxls()">{{$t('exports.parkings')}} <i class="icon icon-download-save"></i></div>
      <div :class="{export: true, btn: true, disabled: !canDownloadReport}"
        @click="downloadExcelReport()">{{$t('exports.charts')}} <i class="icon icon-download-save"></i></div>
    </div>
    <current-states :visibleselectedpss="visibleselectedpss" ></current-states>
  </div>
</div>
</template>

<style lang="less">
@import '/global';

#stats{
  height: 100%;
  overflow-y: scroll;

  .r-c{
    min-height: calc(100% - 65px);
  }

  .r-b{
    height: 40px;
    .icon {
      margin-left: 10px;
    }
  }

  .timeranges{
    display: flex;
    margin: 10px 5px;
    .vendor(user-select, none);

    span{
      flex: 1 0 100px;
      margin: 0 5px;
      text-align: center;
      padding: 8px 5px;
      cursor: pointer;

    }
  }

  .timerange, .debug-query{
    display: flex;
    margin: 0 5px;
    .container {
      margin: 0 5px;      
    }

    .error{
      outline: 2px solid red;
      outline-offset: -2px;
    }

    .flatpickr-input{
      flex: 1 0 150px;
      text-align: center;

      &:focus{
        outline: @color-accent auto 5px;
      }
    }

    .v-select{
      flex: 0 0 120px;
      margin: 0 5px;
      .dropdown-toggle{
        background-color: #fff;
        border-radius: @border-radius-input;
      }
      &.open .dropdown-toggle{
        outline: @color-accent auto 5px;
      }
      .dropdown-menu .active a{
        background: rgba(50,50,50,.1);
        color: @color-base-fg;
      }
      .dropdown-menu .highlight a,
      .dropdown-menu li:hover a {
        background: @color-accent;
        color: #fff;
      }
    }
    .btn{
      text-align: center;
      padding: 2px 18px;
      margin: 0 5px 0 10px;
      .icon{
        font-size: @font-size-icon;
      }
    }
  }

  .debug-query{
    margin-top: 12px;
  }

  .export{
    padding: 5px 10px;
    float: right;
    margin-right: 15px;
  }
}

.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.selected:focus,
.flatpickr-calendar .flatpickr-day.selected:hover{
  background: @color-accent;
  border-color: @color-accent;
}
</style>

<script>
import Chart from './chart.vue';
import Histogram from './histogram.vue';
import ParkingHistory from './parking-history.vue';
import CurrentStates from './current-states.vue';
import datetimepicker from './datetimepicker.vue';
import VSelect from 'vue-select';
import {use_v2_api} from '../../config.yml';

import model from '../model';

import 'moment/locale/hu';

export default {
  name: 'stats',
  props: { visibleselectedpss: Array },
  components: { Chart, Histogram, datetimepicker, VSelect, ParkingHistory, CurrentStates },
  data(){
    return {
      qtime: model.qtime,
      queryInfo: model.query.result.info,
      use_v2_api: use_v2_api,
      charts: model.charts
    }
  },
  computed: {
    canDownloadReport() { return this.queryInfo.l1HasData || (this.queryInfo.l0HasData && this.queryInfo.histoHasData) },
    canDownloadParkings() { return this.queryInfo.l0HasData }
  },
  mounted(){
    model.query.run();
  },
  methods: {
    runFT(){
      return model.query.runFT();
    },
    exportxls(){
      if (this.canDownloadParkings) {
        return model.query.exportxls();
      }      
    },
    downloadExcelReport(){
      if (this.canDownloadReport) {
        return model.query.downloadExcelReport();
      }      
    }
  },
  watch: {
    'qtime.start': function() {
      if(this.qtime.start > this.qtime.end){
        this.qtime.end =  this.qtime.start
      }
    },
    'qtime.end': function() {
      if(this.qtime.start > this.qtime.end){
        this.qtime.start =  this.qtime.end
      }
    }
  }

};
</script>
