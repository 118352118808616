<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="settings">
  <div class="title">{{$t('common.edit')}}</div>
  <router-link tag="i" class="btn closebtn icon icon-cross" to="/main"></router-link>
  <router-view class="settingsbox" :selectedpss="selectedpss" :visibleselectedpss="visibleselectedpss"></router-view>
</div>
</template>

<style lang="less">
@import '/global';
@import '/common';

.settings{
  padding: 10px 40px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 100%;

  &>.closebtn{
    position: absolute;
    right: 14px;
    top: 0;
    margin: 10px;
    transition: color .2s ease;
  }

  .settingsbox{
    max-width: 1000px;
    min-width: 570px;
    .header input[type=text] {
      border: 1px solid #999;
    }

  }

  input[type=text], input[type=number] {
    width: calc(100% - 18px);
    border: 1px solid rgba(60,60,60,.26);
    padding: 8px;
  }
  input[type=checkbox]{
    background-color: white;
    -webkit-appearance: none;
    margin: 0;
    border: 1px solid rgba(60,60,60,.26);
    padding: 8px;
    /*&:focus{
      outline: none;
    }*/
    &:checked{
      background-color: #eeeded;
      //background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' overflow='visible' stroke='grey' stroke-width='4' stroke-linecap='round'%3E%3Cline x2='50' y2='50' /%3E%3Cline x1='50' y2='50' /%3E%3C/svg%3E ");
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 270 270' %3E%3Cpath d='M 30,180 90,240 240,30' style='stroke:%23000; stroke-width:30; fill:none' /%3E%3C/svg%3E");
    }
  }
  textarea {
    resize: none;
    width: 100%;
    height: 100px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

}

</style>

<script>
export default{
  name: 'settings',
  props: { selectedpss: Array, visibleselectedpss: Array }
};
</script>
