<template>
  <div id="langsl">
    <a v-for="lang in langs" :key="lang" :class="{selected: selected === lang, tab: true}" @click="select(lang);$event.preventDefault();" href="#"><span>{{lang.toUpperCase()}}</span></a>
  </div>
</template>

<style lang="less">
@import '/global';

#langsl{
  color: @color-base-fg;
  width: 100%;
  margin-top: @border-radius-block / 1.5;
  display: flex;
}

.tab-center {
  justify-content: center;
}

a {
  &.tab {
    span {
      padding: 7px;
      border-radius: 8px;
      border: transparent 0 solid;
      background: transparent;
    }
    border-bottom: 2px solid @color-border;
    padding: 8px 4px;
    margin: 0 0 0;

    color: @color-base-fg;
    text-decoration: none;
    cursor: pointer;

    &.selected{
      color: @color-accent-pass;
      font-weight: bold;
      border-bottom-color: @color-accent;
    }

    &:hover {
      span {
        background-color: @color-accent-very-light;
      }
    }
    &:focus {
      span {
        background-color: @color-accent-very-light;
        outline: 2px solid @color-base-fg;
      }
      // Hide the default focus outline
      outline: none;
    }
  }
}
</style>

<script>
export default {
  name: 'langsl',
  data(){
    return {
      langs: ['en', 'hu'],
      selected: this.$i18n.locale
    };
  },
  methods: {
    select(lang){
      this.selected = lang;
      this.$i18n.locale = lang;
    }
  }
};
</script>


<!--
################################################################################
#                                                                              #
# COPYRIGHT Ericsson 2018                                                      #
#                                                                              #
# The copyright to the computer program(s) herein is the property of Ericsson  #
# AB. The programs may be used and/or copied only with written permission      #
# from Ericsson AB. or in accordance with the terms and conditions stipulated  #
# in the agreement/contract under which the program(s) have been supplied.     #
#                                                                              #
################################################################################
-->
