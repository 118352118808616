<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
  <div class="loading">
    <div class="title">{{$t('title')}}</div>
    <svg viewBox="0 0 320 160">
      <g>
        <path class="logo background"
          d="m 300.20073,74.194474 c -6.41,3.13 -16.54,8.64 -29.95,8.64 -11.92,0 -21.16,-4.47 -24.14,-13.41 38.59,-19.97 65.71,-31.89 65.71,-47.53 0,-10.73 -9.68,-17.8799999 -28.76,-17.8799999 -31.14,0 -70.33,24.1399999 -70.33,56.6199999 0,1.79 0.15,3.58 0.3,5.36 -4.47,2.38 -12.22,7.15 -17.13,7.15 -2.68,0 -4.02,-1.34 -4.02,-4.17 0,-9.98 12.22,-26.67 13.86,-42.91 1.49,-13.86 -7.6,-22.0499999 -19.37,-22.0499999 -12.65,0 -21.5,4.0499997 -31.85,10.5499999 -0.17,-5.9600002 -4.55,-10.5499999 -11.36,-10.5499999 -9.39,0 -13.41,6.4099999 -17.13,14.5999999 -3.72,8.49 -10.88,11.17 -16.54,11.17 -4.92,0 -8.94,-1.79 -11.32,-7.9 -4.62,-11.9200002 -16.54,-17.8799999 -27.86,-17.8799999 -7.46,0 -13.56,1.9 -18.52,4.0499997 -1.85,-2.8299997 -5.2,-4.0499997 -9.94,-4.0499997 -18.64,0 -37.1300006,35.4099999 -37.6800006,61.2499999 0,0.19 0,0.38 -0.01,0.57 v 0.61 c 0,22.05 12.3700006,37.850006 33.9700006,37.850006 26.82,0 52.15,-27.860006 60.34,-51.700006 5.96,-0.15 13.86,-1.79 18.18,-3.87 -9.39,26.22 -15.94,34.42 -15.94,43.95 0,5.210005 4.32,9.240006 9.83,9.240006 13.41,0 19.82,-18.620006 32.48,-49.320006 8.79,-21.46 17.73,-26.67 25.78,-26.67 6.11,0 8.79,2.83 8.79,7.75 0,12.37 -15.2,30.54 -15.2,49.47 0,12.52 8.34,17.580006 18.18,17.580006 11.32,0 28.16,-11.620006 40.08,-17.580006 9.98,12.37 24.14,17.730006 41.57,17.730006 20.26,0 48.42,-12.660006 48.42,-22.950006 0,-4.77 -4.62,-6.56 -10.43,-3.72 v 0 z m -266.11,10.88 c -4.62,0 -7.15,-3.13 -7.15,-9.24 0,-20.71 22.5,-55.28 39.63,-55.28 5.36,0 7.75,2.83 7.75,8.49 0,19.67 -24.73,56.02 -40.23,56.02 v 0 z m 243.31,-62.43 c 4.92,0 9.68,1.04 9.68,4.32 0,2.98 -4.47,5.81 -9.68,8.34 l -33.82,16.84 c 0,-18.62 22.8,-29.5 33.82,-29.5 z"/>
        <path class="logo progress"
          d="m 300.20073,74.194474 c -6.41,3.13 -16.54,8.64 -29.95,8.64 -11.92,0 -21.16,-4.47 -24.14,-13.41 38.59,-19.97 65.71,-31.89 65.71,-47.53 0,-10.73 -9.68,-17.8799999 -28.76,-17.8799999 -31.14,0 -70.33,24.1399999 -70.33,56.6199999 0,1.79 0.15,3.58 0.3,5.36 -4.47,2.38 -12.22,7.15 -17.13,7.15 -2.68,0 -4.02,-1.34 -4.02,-4.17 0,-9.98 12.22,-26.67 13.86,-42.91 1.49,-13.86 -7.6,-22.0499999 -19.37,-22.0499999 -12.65,0 -21.5,4.0499997 -31.85,10.5499999 -0.17,-5.9600002 -4.55,-10.5499999 -11.36,-10.5499999 -9.39,0 -13.41,6.4099999 -17.13,14.5999999 -3.72,8.49 -10.88,11.17 -16.54,11.17 -4.92,0 -8.94,-1.79 -11.32,-7.9 -4.62,-11.9200002 -16.54,-17.8799999 -27.86,-17.8799999 -7.46,0 -13.56,1.9 -18.52,4.0499997 -1.85,-2.8299997 -5.2,-4.0499997 -9.94,-4.0499997 -18.64,0 -37.1300006,35.4099999 -37.6800006,61.2499999 0,0.19 0,0.38 -0.01,0.57 v 0.61 c 0,22.05 12.3700006,37.850006 33.9700006,37.850006 26.82,0 52.15,-27.860006 60.34,-51.700006 5.96,-0.15 13.86,-1.79 18.18,-3.87 -9.39,26.22 -15.94,34.42 -15.94,43.95 0,5.210005 4.32,9.240006 9.83,9.240006 13.41,0 19.82,-18.620006 32.48,-49.320006 8.79,-21.46 17.73,-26.67 25.78,-26.67 6.11,0 8.79,2.83 8.79,7.75 0,12.37 -15.2,30.54 -15.2,49.47 0,12.52 8.34,17.580006 18.18,17.580006 11.32,0 28.16,-11.620006 40.08,-17.580006 9.98,12.37 24.14,17.730006 41.57,17.730006 20.26,0 48.42,-12.660006 48.42,-22.950006 0,-4.77 -4.62,-6.56 -10.43,-3.72 v 0 z m -266.11,10.88 c -4.62,0 -7.15,-3.13 -7.15,-9.24 0,-20.71 22.5,-55.28 39.63,-55.28 5.36,0 7.75,2.83 7.75,8.49 0,19.67 -24.73,56.02 -40.23,56.02 v 0 z m 243.31,-62.43 c 4.92,0 9.68,1.04 9.68,4.32 0,2.98 -4.47,5.81 -9.68,8.34 l -33.82,16.84 c 0,-18.62 22.8,-29.5 33.82,-29.5 z"/>
        <path class="underline background"
              d="m 309.28073,120.23448 c -43.66,-4.92 -85.23,-7.45 -125.46,-6.85 -58.56,0.89 -130.82,8.05 -167.47,22.35 -8.4900006,3.28 -11.4700006,7.6 -10.8800006,12.66 0.74,5.36 6.2600006,8.19 15.5000006,6.85 31.89,-4.47 54.24,-18.18 132.76,-25.48 71.07,-7.9 114.43,-4.92 154.36,-1.19 4.17,0.45 6.85,-0.6 7,-3.43 0.3,-2.38 -1.94,-4.47 -5.81,-4.92 v 0 z"/>
        <path class="underline progress"
              d="m 309.28073,120.23448 c -43.66,-4.92 -85.23,-7.45 -125.46,-6.85 -58.56,0.89 -130.82,8.05 -167.47,22.35 -8.4900006,3.28 -11.4700006,7.6 -10.8800006,12.66 0.74,5.36 6.2600006,8.19 15.5000006,6.85 31.89,-4.47 54.24,-18.18 132.76,-25.48 71.07,-7.9 114.43,-4.92 154.36,-1.19 4.17,0.45 6.85,-0.6 7,-3.43 0.3,-2.38 -1.94,-4.47 -5.81,-4.92 v 0 z"/>
      </g>
    </svg>
    <div class="label">{{$t('loading')}} {{ progress }}%</div>
    <div class="progress-bar">
      <div class="progress-value" v-bind:style="{width: progress + '%'}"></div>
    </div>
  </div>
</template>

<style lang="less">
@import '/global';

@-webkit-keyframes loading {
  0%   { stroke-dashoffset: 0; }
  100%   { stroke-dashoffset: 1218.5870361328125; }
}
@-moz-keyframes loading {
  0%   { stroke-dashoffset: 0; }
  100%   { stroke-dashoffset: 1218.5870361328125; }
}
@-o-keyframes loading {
  0%   { stroke-dashoffset: 0; }
  100%   { stroke-dashoffset: 1218.5870361328125; }
}
@keyframes loading {
  0%   { stroke-dashoffset: 0; }
  100%   { stroke-dashoffset: 1218.5870361328125; }
}
.loading{
  width: 100%;
  height: 100%;
  padding-top: 5%;
  div{
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .title{
    font-family: Mont, sans-serif;
    font-weight: 700;
    text-transform: uppercase;

    margin-bottom: 120px;
    font-size: 34px;
  }
  .label{
    margin-top: 90px;
    font-size: 26px;
  }
  svg{
    width: 480px; height: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    path{
      fill: none;
      stroke-width: 3;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
    }
    .background{
      stroke: #e3e3e3;
    }
    .progress{
      stroke: @color-accent;
      stroke-dasharray: 609.2935180664062 609.2935180664062;
      -webkit-animation: loading 5s ease-out infinite; /* Safari 4+ */
      -moz-animation:    loading 5s ease-out infinite; /* Fx 5+ */
      -o-animation:      loading 5s ease-out infinite; /* Opera 12+ */
      animation:         loading 5s ease-out infinite; /* IE 10+, Fx 29+ */
    }
  }
}
.progress-bar {
  position: relative;
  background-color: #e3e3e3;
  height: 8px;
  width: 160px;
  margin: 20px auto 0;
  .progress-value {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background-color: @color-accent;
    transition: all 0.1s;
  }
}
</style>

<script>
export default {
  name: 'loading',
  props: ['progress'],
};
</script>
