<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="search">
  <div class="search-inputs">
    <v-icon name="search"></v-icon>
    <input type="text" v-model="text"  @keydown.esc="text = ''"
    :placeholder="$t('common.search_placeholder')"
    />
    <div class="globe" :class="{active: showGeoResults}" @click="showGeoResults=!showGeoResults" @mousedown.prevent="" :title="$t('common.search_place')">
      <v-icon name="globe"></v-icon>
    </div>
    <div class="close" :class="{active: text != ''}"  @click="text = ''" @mousedown.prevent=""><i class="closebtn icon icon-cross" :title="$t('common.search_empty')"></i></div>
  </div>
  <div class="search-results" v-if="results.length > 0">
    <a class="result" v-for="r in results" :key="r.type + '@' + r.text" href="#" v-on:click.stop.prevent="r.click()">
      <span class="text">{{ r.text }}</span> <span class="type" v-if="r.type !== 'geo'">{{$t('common.' + r.entity)}}</span>
      <span class="clearfix"></span>
    </a>
  </div>
</div>
</template>

<style lang="less">
@import '/global';
.search{
  .search-inputs {
    overflow: visible;
  }
  input[type=text]{
    display: inline-block;
    vertical-align: middle;
    border: 1px solid rgba(60,60,60,.26);
    padding: 2px 4px;
    height: 20px;
    background-color: @color-light-bg;
    color: @color-base-fg;
    margin-left: 10px;
    margin-right: 10px;
    outline: none;
  }
  input[type=checkbox] {
    vertical-align: middle;
  }

  .globe, .close {
    display: inline-block;
    vertical-align: middle;
    opacity: 0.5;
    border-radius: 12px;
    cursor: pointer;
    user-select: none;
    &:hover {
      background: rgba(0,0,0,0.5);
    }
    &.active {
      opacity: 1;
    }
  }

  .globe {
    padding: 2px 6px;
    margin-right: -6px;
  }

  .close {
    padding: 2px 4px;
    margin: 1px 0 0 8px;
  }

  .search-results {
    // font-family: @app-font-family;
    // font-size: @app-font-size;

    max-height: 420px;
    // width: 360px;
    width: 420px;
    overflow-y: auto; 
    border-collapse: collapse;   
    border-radius: 3px;
    margin: 6px 0;

    .result{
      &:focus {
        background-color: @color-header-bg;
        color: @color-header-fg;
      }

      display: block;
      text-decoration: none;
      background: @color-base-bg;
      color: @color-base-fg;
      border-bottom: 1px solid @color-border;
      padding: 2px 6px;

      .type {
        float: right;
        background: @color-dark-bg;
        color: @color-dark-fg;
        border-radius: 5px;
        padding: 0 4px;
        font-size: 11px;
        line-height: 14px;
        margin-top: 3px;
      }

      .clearfix {
        display: block;
        clear: both;
      }
    }
  }

  .fa-icon{
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 22px;
    font-size: @font-size-icon;
  }
}
</style>

<script>
import m from '/model';

class Clickable {
  constructor (text, ref, details) {
    this.text = text;
    this.ref = ref; 
    this.details = details;
  }
  get type() { return 'none' }
  get entity() { return 'none' }
  click() { return }
}
class ParkingSpaceResult extends Clickable {
  get type() { return 'ps' }
  get entity() { return 'parking_space' }
  click() { 
    m.selection.clear(); 
    m.selection.togglePS(this.ref);
    let ps = this.ref;
    ps.highlight = true;  
    setTimeout(function() { ps.highlight = false; }, 650);
  }
}
// class ParkingSensorResult extends Clickable {
//   get type() { return 's' }
//   get entity() { return 'parking_sensor' }
//   click() { console.log('click-sensor', this.ref); return }
// }
class GroupResult extends Clickable {
  get type() { return 'g' }
  get entity() { return 'parking_space_group' }
  click() {
    m.selection.clear();
    m.selection.toggleList(this.ref);
  }
}
class ZoneResult extends Clickable {
  get type() { return 'z'  }
  get entity() { return 'parking_zone' }
  click() { m.selection.clear(); m.selection.toggleList(this.ref); }
}

export default {
  name: 'search',
  props: ['center'],
  data(){
    return {
      m,
      text: '',
      showGeoResults: false,
      results: []
    };
  },
  methods: {
    getMessage: function (key) {
      return this.$t(key);
    },
    handleClick: function(res) {
      this.m.places.getDetails(res.place_id, res => {
        this.$emit('highlightLocation', [res.geometry.location.lng(), res.geometry.location.lat()]);
      })
    },
    updateResults() {
      let results = [];
      let needle = this.text.toLocaleLowerCase().trim();
      if (needle === '') {
        this.results = results;
        return;
      }
      let isMatching = text=>text.toLocaleLowerCase().match(needle)
      // let startTime = performance.now();
      // Parking spaces
      results = results.concat(m.parkingspaces.parking_spaces
        .filter(ps=>isMatching(ps.name) || (ps.parking_sensor && isMatching(ps.parking_sensor.name)))
        .map(ps=>{return new ParkingSpaceResult(ps.name + (ps.parking_sensor ? ' (' + ps.parking_sensor.name + ')'  : ''), ps)})
      );
      // Parking sensors
      // results = results.concat(m.parkingsensors.list
      //  .filter(s=>/*s.id != null && isMatching(s.id) ||*/ s.name != null && isMatching(s.name))
      //  .map(s=>{return new ParkingSensorResult(s.name, s, s)})
      // );
      // Groups and Zones
      results = results.concat(m.groups.list
        .filter(set=>set.name != null && isMatching(set.name))
        .map(set=>{return new GroupResult(set.name + ' (' + this.getMessage('pgtypes.' + set.type) + ')', set)})
      );
      results = results.concat(m.zones.list
        .filter(set=>set.name != null && isMatching(set.name))
        .map(set=>{return new ZoneResult(set.name, set)})
      );
      if (this.showGeoResults) {
        this.m.places.search(needle, this.center, res => {
          this.results = [...(res ? res.map(e => ({
            type: 'geo',
            text: e.description,
            click: () => this.handleClick(e)
          })) : []), ...results];
        });
      } else {
        this.results = results;
      }
      // let endTime = performance.now();
      // console.debug('Search - elapsed time (msec): ', (endTime - startTime));
    }
  },
  watch: {
    showGeoResults(){
      this.updateResults();
    },
    text(){
      this.updateResults();
    }
  }
};
</script>
