<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="leftmenu">
  <leftmenu-filter></leftmenu-filter>

  <div class="header">
    {{$t('common.parking_zones')}}
    <spinner v-if="working"></spinner>
  </div>
  <div class="tree-container zones">
    <leftmenu-pslist v-for="zone in zone_list" v-bind:key="zone.id" :pslist="zone"></leftmenu-pslist>
  </div>
  <div class="header">
    {{$t('common.parking_space_groups')}}
    <spinner v-if="working"></spinner>
  </div>
  <div v-for="row in [0, 1]" v-bind:key="row" class="filter group-filter">
      <span v-for="f in groupFilters" v-bind:key="f.value" v-if="(f.row || 0) == row" v-show="!f.hidden"
        :class="['btn input-style', f.active ? 'active' : 'inactive', 'filter-' + f.value]" :title="f.value"
        @click="toggleGroupFilter(f)"
        :style="[row > 0 ? {'margin-top': '-2px'} : {}]"
      >{{$t('pgtypes.' + f.value)}}</span>
  </div>
  <div class="tree-container groups">
    <leftmenu-pslist v-for="group in group_list" v-bind:key="group.id" v-if="groupFilters[group.type].active" :pslist="group"></leftmenu-pslist>
  </div>

  <!--div class="bottom-line">
    <div class="toggle-map" @click="settings.hideMap = !settings.hideMap"><i class="icon icon-chevron-left" v-if="!settings.hideMap"></i><i class="icon icon-map"></i><i class="icon icon-chevron-right" v-if="settings.hideMap"></i></div>
  </div-->
</div>
</template>

<style lang="less">
@import '/global';

.leftmenu{
  position: absolute;

  width: @left-pane-width;

  @leftmenu-padding: 5px; // 5px is used elsewhere
  height: calc(100% - 2*@leftmenu-padding);
  padding: @leftmenu-padding;

  background-color: @color-base-bg;
  color: @color-base-fg;
  display: flex;
  flex-direction: column;
  .vendor(user-select, none);
  box-shadow: 0px 0px 15px rgba(0,0,0,0.5);
  z-index: 10;

  .header{
    font-family: @font-family-header;
    font-size: @font-size-l;
    font-weight: @font-weight-header;

    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    padding: 12px 28px 10px 8px;
    border-top: 1px solid @color-border;
    border-bottom: 1px solid @color-border;
    .loader {
      width: 20px;
      height: 20px;
      border-width: 4px;
    }
  }
  .group-filter {
    display: flex;
    flex-wrap: wrap;
    span {
      padding: 4px;
      /*max-width: 60px;*/
      overflow: hidden;      
      text-overflow: ellipsis;
      flex: 1 1 0em;
    }
  }

  .tree-container{
    flex: 1 1 auto;
    padding-left: 10px;
    overflow-y: auto;
    min-height: 200px;
    &.zones {
      max-height: 200px;
      overflow: auto;
    }
  }

  .bottom-line{
    flex: 0 0 auto;
    border-top: 1px solid @color-border;
    padding: 15px 0;

    span{
      float: left;
      margin-left: 8px;
      line-height: 16px;
      font-size: 12px;
      color: #565656;
      a{
        text-decoration: none;
        color: inherit;
      }
    }
  }
}
</style>

<script>
import LeftmenuFilter from './leftmenu-filter.vue';
import LeftmenuPslist from './leftmenu-pslist.vue';

import m from '../model';
import Spinner from '../settings/spinner';

export default{
  name: 'leftmenu',
  components: { Spinner, LeftmenuFilter, LeftmenuPslist },
  data(){
    var groupFilters = {
      'L': {value:'L', active: true }, // Location
      'S': {value:'S', active: true }, // Support
      'P': {value:'P', active: true },  // Private
      'D': {value:'D', active: false, row: 1 },  // Display
      'A': {value:'A', active: false, row: 1 }  // Attendant
    };
    return {
      settings: m.settings,
      working: m.parkingspaces.working,
      zone_list: m.zones.list,
      group_list: m.groups.list,
      groupFilters: groupFilters
    };
  },
  methods: {
    toggleGroupFilter(f){
      // Multiple selections
      let activeLen = Object.values(this.groupFilters).filter(f=>f.active).length;
      if (!f.active || activeLen > 1) { // At least one filter must be selected
        f.active = !f.active;
      }
    }
  }
};
</script>
