<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="timetable">
  <div class="row">
    <div class="time-col">
      <div class="h-label">00:00</div>
      <div class="h-label">06:00</div>
      <div class="h-label">12:00</div>
      <div class="h-label">18:00</div>
      <div class="h-label">24:00</div>
    </div>
    <div class="grid days">
      <div v-for="day in days" :key="day" class="day">
        <div class="bar-container">
          <a href="javascript:void(0)" v-for="period in periodsByDay(day)" :key="period.index"
              v-bind:class="{'bar-time': true, 'invalid': period.isInvalid, 'selected': isSelected(period.index)}"
              v-bind:style="period.barStyle"
              @click="selectPeriod(period.index)">
            <div class="tooltip">
              <div>{{$t('days_of_week.' + day)}} {{getTimeText(period.from)}} - {{getTimeText(period.to)}}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="time-col"></div>
    <div class="grid labels">
      <div class="v-label" v-for="day in days" :key="day">{{$t('days_of_week.' + day)}}</div>
    </div>
  </div>
</div>
</template>

<style lang="less">
@import '/global';

.timetable{
  position: relative;
  overflow: hidden;
  padding-top: 3px;

  .row {
    display: flex;
  }
  .time-col {
    font-size: 10px;
    line-height: 20px;

    width: 25px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #999;
    margin-top: -8px;
    margin-bottom: -8px;
    user-select: none;
  }
  .v-label {
    font-size: 13px;
    line-height: 32px;

    overflow: hidden;
    text-align: center;
    color: #999999;
    user-select: none;
  }
  .grid {
    display: flex;
    flex: 1 1 auto;
    & > * {
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
  }
  .days {
    border: 1px solid #ddd;
  }
  .day {
    display: flex;
    flex-direction: column;
    height: 160px;
    &:not(:first-child) {
      border-left: 1px solid #eee;
    }

    .bar-container {
      border-bottom-color: transparent;
      flex: 1 1 auto;
      position: relative;
      &:before, &:after { // grids
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        border-top: 1px solid #eee;
        z-index: 1;
      }
      &:before {
        top: 25%;
        bottom: 50%;
        border-bottom: 1px solid #eee;
      }
      &:after {
        top: 75%;
        bottom: 0;
      }
    }
    .bar-time {
      position: absolute;
      display: block;
      left: 5px;
      right: 5px;
      background: @color-chart-plot;
      z-index: 2;
      &.selected {
        background-color: var(--acc2);
        z-index: 4;
      }
      &.invalid {
        background-color: magenta;
        z-index: 3;
      }
      &:focus {
        border: 2px solid black;
        z-index: 5;
      }
      &:hover .tooltip {
        display: block;
      }
      .tooltip {
        display: none;
        position: absolute;
        left: 50%;
        top: 100%;
        margin-top: 5px;
        margin-left: -50px;
        line-height: 20px;
        background: white;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, .5);
        padding: 10px;
        border-radius: 5px;
        white-space: nowrap;
        user-select: none;
        text-align: center;
        &:after {
          content: '';
          position: absolute;
          bottom: 100%;
          left: 45px;
          border: 10px solid transparent;
          border-bottom-color: white;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'timetable',
  components: {},
  props: {
    selected: Number,
    periods: Array,
    editMode: Boolean,
  },
  data(){
    return {
      days: [0, 1, 2, 3, 4, 5, 6, 7]
    };
  },
  methods: {
    getTimeText(time) {
      if (time >= 1439) {
        return '24:00';
      }
      const hours = Math.floor(time/60);
      const minutes = Math.floor(time%60);
      return (hours<10?'0':'') + hours + ':' + (minutes<10?'0':'') + minutes;
    },
    periodsByDay(day) {
      return this.periods.map((p, index) => ({...p, index})).filter(p => p.day === day).map(p => {
        let from = p.from, to = p.to;
        if (from >= to) {
          to = from + 60;
        }
        return {
        ...p,
        isInvalid: p.from >= p.to,
        barStyle: {
          top:    Math.min(96, Math.max(0, from / 1440 * 100)) + '%', 
          bottom: Math.min(96, Math.max(0, ((1440-to) / 1440 * 100))) + '%'}
        };
      });
    },
    selectPeriod(index) {
      if (this.editMode) {
        this.$parent.selectPeriod(index);
      }
    },
    isSelected(index) {
      return this.editMode && index === this.selected;
    }
  }
};
</script>
