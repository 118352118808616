<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="parkingspace box">
  <div class="header" v-if="working && ps">
    <div class="text">{{ps.name}}</div>
    <div class="pushright"></div>
    <spinner></spinner>
  </div>
  <div class="header" v-if="!working">
    <div v-if="!edit" class="text">{{ps.name}}</div>
    <input type="text" v-if="edit" v-model="newvals.name" :class="{error: errors.name}">
    <div class="pushright"></div>
    <div v-if="edit" @click="cancelEditing" class="btn"><i class="icon icon-cross"></i></div>
    <div v-if="edit" @click="finishEditing" class="btn"><i class="icon icon-check"></i></div>
    <div v-if="allow.ps_update && !edit && !deleting" @click="startEditing" class="btn"><i class="icon icon-edit"></i></div>
    <div v-if="deleting" @click="deleteThis()" class="btn"><i class="icon icon-trashcan"></i></div>
    <div v-if="deleting" @click="deleting=false" class="btn"><i class="icon icon-cross"></i></div>
    <div v-if="allow.ps_delete && !deleting && !adding && !edit" @click="deleting=true" class="btn"><i class="icon icon-trashcan"></i></div>
  </div>
  <div class="edit">
    <div>{{$t('common.parking_zone')}}</div>
    <div v-if="!edit">{{ps.parking_zone.list.name}}</div>
    <div v-if="edit"><v-select :clearable="false" :options="zone_list" label="name" :disabled="working"
      v-model="newvals.parking_zone" :class="{error: errors.parking_zone}"
      ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select></div>

    <div>{{$t('settings.spaceedit.type')}}</div>
    <div v-if="!edit">{{$t('pstypes.'+ps.type)}}</div>
    <div v-if="edit"><v-select :clearable="false" :options="['N', 'R', 'L']" :get-option-label="i=>$t('pstypes.'+i)"
      v-model="newvals.type" :class="{error: errors.type}" :disabled="working"
      ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select></div>

    <div>{{$t('settings.spaceedit.layout')}}</div>
    <div v-if="!edit">{{$t('pslayouts.'+ps.layout)}}</div>
    <div v-if="edit"><v-select :clearable="false" :options="['L', 'P', 'F']" :get-option-label="i=>$t('pslayouts.'+i)"
      v-model="newvals.layout" :class="{error: errors.layout}" :disabled="working"
      ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select></div>

    <div>{{$t('settings.spaceedit.address')}}</div>
    <div v-if="!edit">{{ps.address}}</div>
    <div v-if="edit"><input type="text" v-model="newvals.address" :class="{error: errors.address}" :disabled="working"></div>

    <div>{{$t('settings.spaceedit.location')}}</div>
    <div v-if="!edit">{{ps.lonLat[1]}}&deg;&nbsp;&nbsp;{{ps.lonLat[0]}}&deg;</div>
    <div v-if="edit" class="picker">
      <div class="loc">
        <span class="coord">
          <input type="text" v-model="newvals.latitude" :class="{error: errors.latitude}" :disabled="working"><span>&deg;</span>
        </span>
        <span class="coord">
          <input type="text" v-model="newvals.longitude" :class="{error: errors.longitude}" :disabled="working"><span>&deg;</span>
        </span>
      </div>
      <div class="picker-button">
        <locationpicker :value="[newvals.latitude, newvals.longitude]" v-on:input="updateLocation($event)" :name="ps ? ps.name : ''" :disabled="working"></locationpicker>
      </div>
    </div>

    <div>{{$t('settings.spaceedit.evcharge')}}</div>
    <div v-if="!edit">{{$t('common.'+ps.electric_charging_station)}}</div>
    <div v-if="edit"><input type="checkbox" v-model="newvals.electric_charging_station" :disabled="working"></div>

    <div>{{$t('settings.spaceedit.evonly')}}</div>
    <div v-if="!edit">{{$t('common.'+ps.reserved_for_electric_vehicles)}}</div>
    <div v-if="edit"><input type="checkbox" v-model="newvals.reserved_for_electric_vehicles" :disabled="working"></div>

    <div>{{$t('common.parking_space_groups')}}</div>
    <div v-if="!edit"><span v-for="gc in ps.groups" v-bind:key="gc.id" class="group-list">{{gc.list.name}}</span></div>
    <div v-if="edit"><v-select multiple :options="group_list" label="name" v-model="newvals.groups" :disabled="working"
      ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select></div>

    <div v-if="(!edit && ps.type == 'L') || (edit && newvals.type == 'L')">{{$t('common.loading_hours')}}</div>
    <div v-if="!edit && ps.type == 'L'">{{ps.loading_hours_profile ? ps.loading_hours_profile.name : ''}}</div>
    <div v-if="newvals.type == 'L' && edit">
      <v-select :options="lhp_list" :get-option-label="pp=>pp.name" label="name" v-model="newvals.loading_hours_profile" 
        :clearable="false" :class="{error: errors.loading_hours_profile}" :disabled="working"
        ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select>
    </div>

    <div>{{$t('common.residential_hours')}}</div>
    <div v-if="!edit">{{ps.residential_hours_profile ? ps.residential_hours_profile.name : ''}}</div>
    <div v-if="edit">
      <v-select :options="rhp_list" :get-option-label="pp=>pp.name" label="name" v-model="newvals.residential_hours_profile" 
        :clearable="true" :class="{error: errors.residential_hours_profile}" :disabled="working"
        ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select>
    </div>
  </div>
</div>
</template>

<style lang="less">
@import '/global';

.parkingspace{
  position: relative;
  margin: 15px;

  .error{
    outline: 2px solid red;
    outline-offset: -2px;
  }

  .header{
    input{
      width: 140px;
      padding: 0 5px;
    }
  }
  .picker {
    display: flex;
    & > *:first-child {
      flex: 1 1 auto;
    }
    & > *:last-child {
      margin-left: 10px;
      flex: 0 0 30px;
    }
  }
  .group-list + .group-list:before {
    content: ", ";
  }

  .edit{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 15px 15px 30px 50px;

    .loc{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      .coord{
        span{
          margin-left: -15px;
        }
      }
    }
  }
  .v-select .dropdown-toggle{
    background-color: #fff;
    border-radius: @border-radius-input;
  }
}
</style>

<script>
import VSelect from 'vue-select';
import m from '/model';
import Spinner from './spinner';
import Locationpicker from "./locationpicker";

const validators = {
  name: i=>(i.length > 0),
  parking_zone: i=>(typeof i !== 'undefined'),
  type: i=>(i.length > 0),
  layout: i=>(i.length > 0),
  address: i=>(i.length > 0),
  latitude: i=>((i+'').length > 0 && !isNaN(Number(i))),
  longitude: i=>((i+'').length > 0 && !isNaN(Number(i))),
  loading_hours_profile: (i, e)=>!(e.type === 'L' && !i)
};

export default {
  name: 'parkingspace',
  props: {
    ps: Object,
    adding: {
      type: Boolean,
      default: false
    }
  },
  components: { Locationpicker, Spinner, VSelect},
  data(){
    return {
      working: false,
      allow: m.user.allow,
      zone_list: m.zones.list,
      group_list: m.groups.list,
      lhp_list: m.lhps.list,
      rhp_list: m.rhps.list,
      edit: this.adding || false,
      deleting: false,
      newvals: {
        name: '',
        parking_zone: undefined,
        type: '',
        layout: '',
        address: '',
        latitude: '',
        longitude: '',
        electric_charging_station: false,
        reserved_for_electric_vehicles: false,
        groups: [],
        loading_hours_profile: null,
        residential_hours_profile: null,
      },
      errors: {
        name: false,
        parking_zone: false,
        type: false,
        layout: false,
        address: false,
        latitude: false,
        longitude: false,
        loading_hours_profile: false,
        residential_hours_profile: false,
      }
    };
  },
  computed: {
    readOnly() {
      return !(!this.working && this.edit);
    }
  },
  methods: {
    deleteThis: async function(){
      const res = await m.parkingspaces.deletePS(this.ps);
      this.deleting = !res;
    },
    startEditing(){
      if(!this.adding){
        this.newvals.name = this.ps.name;
        this.newvals.parking_zone = this.ps.parking_zone.list;
        this.newvals.type = this.ps.type;
        this.newvals.layout = this.ps.layout;
        this.newvals.address = this.ps.address;
        this.newvals.longitude = this.ps.lonLat[0];
        this.newvals.latitude = this.ps.lonLat[1];
        this.newvals.electric_charging_station = this.ps.electric_charging_station;
        this.newvals.reserved_for_electric_vehicles = this.ps.reserved_for_electric_vehicles;
        this.newvals.groups = this.ps.groups.map(gc=>gc.list);
        this.newvals.loading_hours_profile     = this.ps.loading_hours_profile || m.lhps.list[0];
        this.newvals.residential_hours_profile = this.ps.residential_hours_profile || null;
        this.edit = true;
      }
    },
    cancelEditing(){
      this.$emit('finished');
      this.edit = this.adding;
    },
    finishEditing: async function(){
      var err = false;
      for(var k in validators){
        if(!validators[k](this.newvals[k], this.newvals)){
          this.errors[k] = true;
          err = true;
        }else{
          this.errors[k] = false;
        }
      }
      if(err) return;

      this.working = true;
      const newvals = Object.assign({}, this.newvals);
      let res;
      if(!this.adding){
        res = await m.parkingspaces.updatePS(this.ps, newvals);
      }else{
        res = await m.parkingspaces.addPS(newvals);
      }
      this.working = false;
      if (res) {
        this.$emit('finished');
        this.edit = this.adding;
      }
    },
    updateLocation(latLon) {
      this.newvals.latitude = latLon[0];
      this.newvals.longitude = latLon[1];
    }
  }
};
</script>
