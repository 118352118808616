<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="settings-groups">
  <div class="box">
    <div class="header">
      <div class="text">{{$t('common.parking_space_groups')}}</div>
      <div class="pushright"></div>
      <div @click="toggleParkingSpaces()" class="btn edge-margins">
        <i class="icon icon-view-list"></i>
        <v-icon :name="isShowAllParkingSpaces ? 'chevron-down' : 'chevron-left'" style="vertical-align: -1px" />
      </div>
      <div v-if="allow.group_create && !groups.hasEmptyGroup()" @click="groups.newEmptyGroup()" class="btn"><i class="icon icon-plus"></i></div>
      <div v-if="groups.hasEmptyGroup()" @click="groups.removeEmptyGroups()" class="btn"><i class="icon icon-cross"></i></div>
    </div>
  </div>
  <group v-for="g in groups.list" v-bind:key="g.id" :g="g" :class="{selectedGroup: g.selected}" :showAll="isShowAllParkingSpaces"
   :selectedpss="selectedpss"></group>
</div>
</template>

<script>
import m from '/model';
import group from './group.vue';

export default{
  name: 'settings-groups',
  components: {group},
  props: { selectedpss: Array },
  data(){
    return {
      groups: m.groups,
      allow: m.user.allow,
      isShowAllParkingSpaces: false
    };
  },
  mounted() {
    m.selection.events.onListSelection = () => {
      setTimeout(() => {
        this.scrollToElement();
      });
    }
  },
  destroy() {
    m.selection.events.onListSelection = null;
  },
  methods: {
    toggleParkingSpaces() {
      this.isShowAllParkingSpaces = !this.isShowAllParkingSpaces;
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName('selectedGroup')[0];

      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
};
</script>
