<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
  <div class="account">
    <div class="title">{{$t('account.user')}}</div>
    <router-link tag="i" class="btn closebtn icon icon-cross" to="/main"></router-link>
  <div class="account-container">
    <div class="box">
      <div class="header">
        <div class="text">{{$t('account.data')}}</div>
      </div>
      <div class="body">
        <div>
          <b>{{$t('account.email')}}:</b>
          <div>{{user.email}}</div>
        </div>
        <div v-if="user.roles">
          <b>{{$t('account.roles')}}:</b>
          <div>{{user.roles}}</div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="header">
        <div class="text">{{$t('account.password')}}</div>
      </div>
      <div class="body">
        <span class="chpw" @click="navChpw">{{$t('account.change')}}</span>
      </div>
    </div>
    <div class="box">
      <div class="header">
        <div class="text">{{$t('account.lang')}}</div>
      </div>
      <div class="body">
        <langsl></langsl>
        <!--span @click="$i18n.locale = 'en'" :style="{'cursor': 'pointer', 'font-weight': $i18n.locale == 'en' ? '800' : '400'}">EN</span>
        <span @click="$i18n.locale = 'hu'" :style="{'cursor': 'pointer', 'font-weight': $i18n.locale == 'hu' ? '800' : '400'}">HU</span-->
      </div>
    </div>
    <div class="box">
      <div class="header">
        <div class="text">{{$t('account.permissions')}}</div>
      </div>
      <div class="body">
        <table>
          <tr>
            <th></th>
            <th>{{$t('account.create')}}</th>
            <th>{{$t('account.read')}}</th>
            <th>{{$t('account.update')}}</th>
            <th>{{$t('account.delete')}}</th>
          </tr>
          <tr v-for="p in perms">
            <td>{{$t('common.' + p.label)}}</td>
            <td><span v-if="user.permissions.has(p.perm + '.Create')">&#x25CF;</span><span v-else>&#x25CB;</span></td>
            <td><span v-if="user.permissions.has(p.perm + '.Read')">&#x25CF;</span><span v-else>&#x25CB;</span></td>
            <td><span v-if="user.permissions.has(p.perm + '.Update')">&#x25CF;</span><span v-else>&#x25CB;</span></td>
            <td><span v-if="user.permissions.has(p.perm + '.Delete')">&#x25CF;</span><span v-else>&#x25CB;</span></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="buildinfo">Build {{lastcommit.hash}} ({{lastcommit.date}})</div>
</div>
</template>

<style lang="less">
@import '/global';

.account{
  padding: 10px 40px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 100%;

  &>.closebtn{
    position: absolute;
    right: 14px;
    top: 0;
    margin: 10px;
    transition: color .2s ease;
  }

  .account-container{
    max-width: 1000px;
    padding-bottom: 100px;

    &>.box{
      margin-bottom: 30px;

      &>.header>.text{
        font-size: 18px;
      }

      &>.body{
        margin: 10px;

        &>div{
          margin-bottom: 5px;

          &>div{
            margin-top: 7px;
            margin-left: 10px;
          }
        }
      }
    }

    .chpw{
      cursor: pointer;
      text-decoration: underline;
    }

    table{
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      tr{
        line-height: 24px;
      }
      tr > td:first-child{
        text-align: left;
      }
      tr:not(:last-child) > td{
        border-bottom: 1px solid #ddd;
      }
    }
  }

  .buildinfo{
    position: absolute;
    bottom: 4px;
    right: 40px;
    font-size: 12px;
  }
}

</style>

<script>
import model from '../model';
import closure from '../settings/closure.vue';
import {lastcommit, urls} from '../../config.yml';
import Langsl from "../settings/langsl.vue";

const perms = [
  {label: 'parking_zone', perm: 'ParkingZone'},
  {label: 'parking_space', perm: 'ParkingSpace'},
  {label: 'parking_space_parking_sensor', perm: 'ParkingSpaceParkingSensor'},
  {label: 'parking_space_group', perm: 'ParkingSpaceGroup'},
  {label: 'parking_sensor', perm: 'ParkingSensor'},
  {label: 'parking_event', perm: 'ParkingEvent'},
  {label: 'parking', perm: 'Parking'},
  {label: 'closure', perm: 'Closure'},
  {label: 'metered_hours_profile', perm: 'MeteredHoursProfile'},
  {label: 'metered_hours_detail', perm: 'MeteredHoursDetail'},
  {label: 'loading_hours_profile', perm: 'LoadingHoursProfile'},
  {label: 'loading_hours_detail', perm: 'LoadingHoursDetail'},
  {label: 'residential_hours_profile', perm: 'ResidentialHoursProfile'},
  {label: 'residential_hours_detail', perm: 'ResidentialHoursDetail'},
  {label: 'group_alert_rule', perm: 'GroupOccupancyAlertRule'},
];

export default{
  name: 'account',
  components: {Langsl},
  data(){
    return {
      user: model.user,
      lastcommit,
      perms
    };
  },
  methods: {
    navChpw(){
      window.location.replace(urls.auth + '#/chpw');
    }
  }
};
</script>
