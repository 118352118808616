<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div>
  <loading v-if="!model.loaded" :progress="model.progress"></loading>
  <div class="navbar" v-if="model.loaded">
    <div class="leftlogo"><img src="../res/onesol-logo-primary-lightbg.svg"></div>
    <div class="divider"></div>
    <div class="title">
      {{$t('title')}}
    </div>
    <div class="btn-round btn-menu btn-dropdown" v-if="model.companies.list.length > 1">
      <span class="parts">
        <i class="icon icon-position"></i><span>{{$t('menu.companies')}}</span><v-icon name="chevron-down" />

      </span>
      <div class="dropdown">
        <div
            v-for="c in model.companies.list"
            v-bind:key="c.id"
            :class="{ddi: true, active: 'companies' in model && c.id == model.companies.selected.id}"
            @click="model.companies.select(c.id)">{{ c.name }}</div>
      </div>
    </div>
    <div class="btn-round btn-menu btn-dropdown" v-if="!model.error">
      <span class="parts">
        <div v-html="model.SvgSettings" class="svg-icon"></div>
        <span>{{$t('common.edit')}}</span><v-icon name="chevron-down" />
      </span>
      <div class="dropdown">
        <router-link tag="div" class="ddi" to="/settings/spaceedit">{{$t('common.parking_spaces')}}</router-link>
        <router-link tag="div" class="ddi" to="/settings/groups">{{$t('common.parking_space_groups')}}</router-link>
        <router-link tag="div" class="ddi" to="/settings/binding" v-if="model.user.permissions.has('ParkingSpaceParkingSensor.Update')">{{$t('menu.settings.binding')}}</router-link>
        <router-link tag="div" class="ddi" to="/settings/closures">{{$t('common.closures')}}</router-link>
        <router-link v-if="model.user.allow.gar_read" tag="div" class="ddi" to="/settings/groupalertrules">{{$t('common.group_alert_rules')}}</router-link>
        <div class="separator"></div>
        <router-link tag="div" class="ddi" to="/settings/paying">{{$t('common.metered_hours')}}</router-link>
        <router-link tag="div" class="ddi" to="/settings/loading">{{$t('common.loading_hours')}}</router-link>
        <router-link tag="div" class="ddi" to="/settings/residential">{{$t('common.residential_hours')}}</router-link>
      </div>
    </div>
    <div class="btn-round btn-menu btn-dropdown">
      <span class="parts">
        <!--i class="vficon iuser"></i-->
        <div v-html="model.SvgUser" class="svg-icon"></div>
        <span>{{$t('common.account')}}</span><v-icon name="chevron-down" />

      </span>
      <div class="dropdown">
        <router-link tag="div" class="ddi" to="/account"  v-if="!model.error">{{$t('common.settings')}}</router-link>
        <div class="ddi" v-if="model.user.is_admin" @click="navAdmin">{{$t('menu.account.admin')}}</div>
        <div class="separator"></div>
        <div class="ddi" @click="model.api.logout">{{$t('menu.account.logout')}}</div>
      </div>
    </div>
  </div>
  <div class="content" v-if="model.loaded && !model.error">
    <leftmenu></leftmenu>
    <side-by-side class="sbs" :close="model.hideMap">
      <template slot="leftpane">
        <m-map :selectedpss="selectedpss" :visibleselectedpss="visibleselectedpss" :highlightedId="model.parkingspaces.highlightedId"></m-map>
      </template>
      <template slot="rightpane">
        <router-view :selectedpss="selectedpss" :visibleselectedpss="visibleselectedpss"></router-view>
      </template>
    </side-by-side>
  </div>
  <div class="content" v-if="model.loaded && model.error">
    <div class="error-message">{{$t('common.app_init_error')}}</div>
  </div>
</div>
</template>

<style lang="less">
@import '/global';
@import '/common';
@import '../lib/eicon/icons';
// @import '../lib/hildafont/hilda';
// @import '../lib/vffont/vodafone';
@import '../lib/onefonts/onefonts';
@import '../lib/vficon';

.svg-icon {
  display: flex;
  align-items: center;
}

html, body{
  margin: 0;
  // font-family: "Ericsson Hilda", Helvetica, Arial, sans-serif;
  font-family: @app-font-family;
  font-size: @app-font-size;
  color: @color-base-fg;
  -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
  image-rendering: -webkit-optimize-contrast;
  --acc1: @color-accent;
  --acc2: #fecb00;
}

input, select, textarea {
  font-family: @app-font-family;
  font-size: @app-font-size;
  color: @color-base-fg;
}
input, select, textarea, .border, .v-select .dropdown-toggle, .input-style, .btn.input-style  {
  border-radius: @border-radius-input;
  border-color: darken(white, 60%); // Black 60%
  &:hover {
    border-color: darken(white, 87%); // Black 87%
  }
}

.block-style, .box,
// Specific items
.chart, .histogram {
  border-radius: @border-radius-block;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16); // Elevation - Low
}

// All title is Mont 700 by default
.title{
  font-family: Mont, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.navbar{
  position: fixed;
  height: @navbar-height;
  width: 100%;
  background-color: @navbar-bg;
  // box-shadow: 0px 0px 15px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.3);

  border-bottom: @navbar-border-color solid @navbar-border-width;

  display: flex;
  // Left align
  justify-content: flex-start;
  // Center vertically
  align-items: center;

  z-index: 10;
  .vendor(user-select, none);

  .title{
    font-family: Mont, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 25px;

    height: @navbar-height;
    line-height: @navbar-height;
    color: @navbar-fg;
    white-space: nowrap;
    // Flex extend
    flex-grow: 1;


    .icon{
      margin-right: 8px;
    }
  }

  .midlogo{
    margin-left: auto;
    margin-right: auto;
    line-height: @navbar-height;
    img{
      height: 80%;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .leftlogo{
    flex-shrink: 1;
    height: calc(100% - 15px);
    padding: 5px 10px;
    img{
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .divider {
    // vertical 4px
    // height: 100%;
    width: 2px;
    height: 60%;
    margin: 15px 15px 15px 10px;
    background-color: @color-border;
  }

  // last .btn-dropdown has right margin 25px
  .btn-dropdown:last-child {
    margin-right: 30px;
  }
  .btn-dropdown {
    height: 100%;
    // height: @navbar-height - 20px;
    margin: 0 10px;

    cursor: pointer;
    line-height: @navbar-height - 20px;
    font-size: @font-size-button;
    // padding: 0px 20px 0 10px;
    white-space: nowrap;

    .parts {
      height: 100%;
      display: flex;
      align-items: center; // Centered vertically

      // Span has same height as button
      span{
        vertical-align: top;
        margin: 0 8px;
      }
      /*svg {
        height: 12px;
        margin-top: 3px;
      }*/
    }

    .dropdown{
      border-radius: @border-radius-input;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      min-width: 200px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
      //box-shadow: 0px 8px 16px 4px rgba(0,0,0,0.4);
      // Only right, bottom and left border
      border: 1px solid @color-border;
      border-top: none;


      overflow: hidden;

      text-align: left;
      display: none;
      position: absolute;
      background-color: @color-base-bg;
      color: @color-base-fg;
      margin-top: -2px;
      margin-left: -10px;

      .ddi{
        padding: 3px 10px;

        &:hover{
          background-color: darken(@color-base-bg, 10%);
        }
        &.router-link-active, &.active {
          background-color: @color-accent;
          color: @color-accent-fg;
        }
      }

      .separator{
        border-top: 1px solid @color-dark-bg;
      }

    }

    &:hover .dropdown{
      display: block;
    }
  }

}

.content{
  position: fixed;
  top: @navbar-height + @navbar-border-width;
  height: calc(100% - @navbar-height);
  width: 100%;

  .sbs{
    position: absolute;
    height: 100%;
    width: calc(100% - @left-pane-width);
    left: @left-pane-width;
  }
}

.error-message {
  position: relative;
  margin: 64px auto;
  border: 2px dashed red;
  padding: 32px;
  font-size: @font-size-xl;
  width: 400px;
  text-align: center;
}

.toasted .primary.error,
.toasted.toasted-primary.error {
  position: relative;
  background-color: white !important;
  border: 2px solid red;
  color: black;
  display: block;
  padding: 16px 64px 16px 16px;
  .action {
    position: absolute;
    right: 8px;
    top: 8px;
    margin: 0;
  }
  p {
    margin-bottom: 0;
  }
}

/* Common button styles */

/*
      background-color: @color-accent;
      color: @color-accent-fg;
      &:hover{
        background-color: lighten(@color-accent, 5%);
      }
 */


.btn, .button-color {
  cursor: pointer;
  user-select: none;

  // background-color: desaturate(@color-accent, 75%);
  background-color: @color-accent;
  color: @color-accent-fg;
  img, .icon {
    filter: brightness(luma(@color-accent-fg)); // Steal darkness from fg color
  }

  &:hover{
    // background-color: lighten(desaturate(@color-accent, 75%), 5%);
    background-color: @color-button-hover;
  }

  &.active{
    background-color: @color-accent;

    &:hover{
      // background-color: lighten(@color-accent, 10%);
      background-color: @color-button-active-hover;
    }
  }

  &.disabled, &.inactive {
    background-color: @color-button-disabled;
    &:hover{
      background-color: darken(@color-button-disabled, 10%);
    }
  }
  &.disabled {
    cursor: not-allowed;
    color: @color-button-disabled-fg;
    img, .icon {
      filter: brightness(luma(@color-button-disabled-fg)); // Steal darkness from fg color
    }
  }
  &.inactive {
    color: @color-dark-fg;
    img, .icon {
      filter: brightness(luma(@color-dark-fg)); // Steal darkness from fg color
    }
  }

}

// On dark theme, we need to adjust the hover color to lighten the button
.theme-light {
  background-color: @color-light-bg;

  .box, .block-style, .chart, .histogram {
    border-color: darken(@color-light-bg, 20%);
    box-shadow: none;
  }

  .label {
    background-color: darken(@color-light-bg, 40%);
  }
}


// Mid theme, background is Mid Gray #E0E0E0
.theme-mid {
  background-color: @color-mid-gray-bg;
  color: @color-mid-gray-fg;

  .box, .block-style, .chart, .histogram {
    border-color: darken(@color-mid-gray-bg, 20%);
    box-shadow: none;
  }

  .label {
    background-color: darken(@color-mid-gray-bg, 40%);
  }
}


.theme-skobeloff {
  background-color: @color-skobeloff;
  //background-color: @color-light-bg;
  color: @color-base-fg;

  // Inverted title color
  > div {
    > img, > .icon {
      color: @color-sidebar-fg;
      filter: brightness(luma(@color-sidebar-fg)); // Steal darkness from fg color
    }

    > div.title {
      color: @color-sidebar-fg;
    }
  }

  .btn, .button-color {

    background: @color-skobeloff;
    color: @color-base-bg;
    outline: 1px solid @color-base-bg;

    &:hover{
      background-color: darken(@color-skobeloff, 10%);
      img, .icon {
        filter: brightness(luma(@color-base-bg)); // Steal darkness from fg color
      }
    }

    &.disabled, &.inactive {
      background-color: brighten(@color-skobeloff, 10%);
      color: darken(white, 38%);
      outline-color: darken(white, 38%);

      img, .icon {
        filter: brightness(1); // Steal darkness from fg color, 1 is default
      }
    }

    &.active{
      // background-color: lighten(desaturate(@color-skobeloff, 20%), 20%);
      // background White 76%
      background-color: darken(@color-skobeloff, 0%);

      &:hover{
        background-color: darken(@color-skobeloff, 10%);
        img, .icon {
          filter: brightness(luma(@color-base-bg)); // Steal darkness from fg color
        }
      }
    }

  }
}

// Outline button, 1px black outline, no background; hover: $accent color 16%
.btn-outline {
  @color-outline-button-fg: @color-base-fg;

  background-color: transparent;
  color: @color-outline-button-fg;
  outline: 1px solid @color-outline-button-fg;
  img, .icon, .vficon {
    filter: brightness(luma(@color-outline-button-fg)); // Steal darkness from fg color
  }

  &:hover{
    // 16% saturation of accent color
    @color-outline-button-hover-fg: @color-accent-hover;
    background-color: @color-outline-button-hover-fg;
  }
}

.btn-menu {
  color: @color-base-fg;
  @color-menu-hover: darken(@color-accent, 10%);

  font-family: Mont, sans-serif;
  font-weight: 700;

  .svg-icon svg {
    height: 20px;
    width: 20px;
  }

  .svg-icon svg {
    path, circle {
      stroke: @color-base-fg;
    }
  }


  // Chevron down
  .fa-icon {
    height: 14px;
  }

  // Color image and icon to black
  > span {
    img, .icon, .vficon {
      filter: brightness(luma(@color-base-fg)); // Steal darkness from fg color
    }
  }

  &:hover > span {
    color: @color-menu-hover;

    i {
      color: @color-menu-hover;
      filter: brightness(0.99); // HACK needed to set font color
    }

    .svg-icon svg {
      path, circle {
        stroke: @color-menu-hover
      }
    }
  }

  // Normal font fanuly and weight again
  .dropdown {
    font-family: @app-font-family;
    font-weight: normal;
  }
}

.btn {
  // Center text
  text-align: center;
  // Border radius smallest
  // border-radius: @border-radius-input;
}


.btn, .btn-md, .btn-round {
  font-size: 16px;

  height: min-content;
  line-height: 24px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 24px;

  padding: 2px 4px;
}


.btn-sm {
  font-size: 14px;

  height: min-content;
  line-height: 22px;
  min-height: 22px;
  min-width: 22px;
  border-radius: 22px;
}

.btn-l, .btn-lg {
  font-size: 20px;

  height: min-content;
  line-height: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 30px;

  padding: 3px 6px;
}



// Last style to apply overrides
.edge-margins {
  // First child has 8px left margin, last child has 8px right margin
  > *:first-child {
    margin-left: 8px;
  }
  > *:last-child {
    margin-right: 8px;
  }
}
.flex-center-start {
  // height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // Any subelement is 100% height
  > * {
    // height: 100%;
    // margin: 0 2px;
  }
  // First child has 8px left margin, last child has 8px right margin
  > *:first-child {
    margin-left: 8px;
  }
  > *:last-child {
    margin-right: 8px;
  }
}

.click{
  cursor: pointer;
}


</style>


<script>
import model from '/model';
import Loading from './loading.vue';
import Leftmenu from './leftmenu';
import SideBySide from './utils/side-by-side.vue';
import MMap from './map';
import SvgSettings from '../res/settings.svg';
import SvgUser from '../res/user.svg';

var SvgSettingsItem = {
  template: SvgSettings
};
var SvgUserItem = {
  template: SvgUser
};

import {urls} from '../config.yml';

export default {
  name: 'app',
  data: ()=>({
    model
  }),
  async mounted() {
    // Load SVG direcly into model
    await fetch(SvgSettings)
        .then(response => response.text())
        .then(text => {
          model.SvgSettings = text;
        });
    await fetch(SvgUser)
        .then(response => response.text())
        .then(text => {
          model.SvgUser = text;
        });
  },
  components: {
    Loading, Leftmenu, SideBySide, MMap
  },
  methods: {
    navAdmin(){
      window.open(urls.backend + '/admin/', '_blank');
    }
  },
  computed: {
    selectedpss(){
      return model.parkingspaces.parking_spaces.filter(ps=>ps.selected);
    },
    visibleselectedpss(){
      const mask = model.filter.mask;
      return model.parkingspaces.parking_spaces.filter(ps=>ps.isVisible(mask) && ps.selected);
    }
  },
}

</script>
