<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="closure box" v-if="ld">
  <div class="header" v-if="working && ld">
    <div class="text">{{ld.name}}</div>
    <div class="pushright"></div>
    <spinner></spinner>
  </div>
  <div class="header" v-if="!working">
    <div v-if="!edit" class="text">{{ld.name}}</div>
    <input type="text" v-if="edit" v-model="newvals.name" :class="{error: errors.name}">
    <div class="pushright"></div>
    <div v-if="edit" @click="cancelEditing" class="btn"><i class="icon icon-cross"></i></div>
    <div v-if="edit" @click="finishEditing" class="btn"><i class="icon icon-check"></i></div>
    <div v-if="!anyEdit && allow.closure_update && canEdit" @click="startEditing" class="btn"><i class="icon icon-edit"></i></div>
    <div v-if="!anyEdit && allow.closure_delete && canDelete" @click="deleteItem" class="btn"><i class="icon icon-trashcan"></i></div>
    <div v-if="allow.closure_delete && canAbort" @click="abortItem" class="btn"><i class="icon icon-video-stop"></i></div>
    <div v-if="isAborted" class="btn aborted"><i class="icon icon-video-pause"></i></div>

    <div v-if="allow.closure_delete && canAbort" @click="startRemoving" class="btn"><i class="icon icon-minus"></i></div>
    <div v-if="ps_removing" @click="cancelRemoving" class="btn"><i class="icon icon-cross"></i></div>
    <div v-if="ps_removing" @click="finishRemoving" class="btn"><i class="icon icon-check"></i></div>
  </div>
  <div class="edit">
    <div>{{$t('settings.closure.start')}}</div>
    <div v-if="!edit"><div class="border">{{dtfmt(ld.start)}}</div></div>
    <div v-if="edit"><datetimepicker v-model="newvals.start" :class="{error: errors.start}" :disabled="working"></datetimepicker></div>

    <div>{{$t('settings.closure.end')}}</div>
    <div v-if="!edit"><div class="border">{{dtfmt(ld.end)}}</div></div>
    <div v-if="edit"><datetimepicker v-model="newvals.end" :class="{error: errors.end}" :disabled="working"></datetimepicker></div>

    <div>{{$t('common.parking_spaces')}}</div>
    <div class="border">
      <div class="space-value-header">
        <span class="value">{{getSetLength()}} {{getSetName()}}</span>
        <span class="action" @click="toggleParkingSpaces()">
          <v-icon :name="isShowParkingSpaces ? 'chevron-down' : 'chevron-left'" />
        </span>
      </div>
      <div v-if="isShowParkingSpaces" class="space-value-content">
        <div v-if="!edit && !ps_removing"><span class="item" v-for="ps in ld.parking_spaces" v-bind:key="ps.id">{{ps.name}}</span></div>
        <div v-if="ps_removing"><span class="removing" v-for="ps in ld.parking_spaces" v-bind:key="ps.id">{{ps.name}}
        <i class="icon icon-trashcan remove" @click="removePs(ps)"></i></span>
        </div>
        <div v-if="edit" :class="{error: errors.parking_space}"><span class="item" v-for="ps in selectedpss" v-bind:key="ps.id">{{ps.name}}</span></div>
      </div>
    </div>

    <div>{{$t('settings.closure.deactivate_sensors')}}</div>
    <div v-if="!edit"><div class="border">{{$t('common.'+ld.deactivate_sensors)}}</div></div>
    <div v-if="edit"><input type="checkbox" v-model="newvals.deactivate_sensors" :disabled="working"></div>
    <div v-if="edit"><small>{{$t('settings.closure.deactivate_sensors_hint')}}</small></div>
    <div v-if="edit"><!-- control placeholder --></div>

    <div>{{$t('settings.closure.comment')}}</div>
    <div v-if="!edit"><div class="border comment">{{ld.comment}}</div></div>
    <div v-if="edit"><textarea class="border" v-model="newvals.comment" :disabled="working"></textarea></div>

    <div v-if="!edit">{{$t('settings.closure.status')}}</div>
    <div v-if="!edit"><div class="border">{{$t('closurestatus.'+ld.status)}}</div></div>
  </div>
</div>
</template>

<style lang="less">
@import '/global';

.closure{
  position: relative;
  margin: 15px;

  .error{
    outline: 2px solid red;
    outline-offset: -2px;
  }

  .aborted {
    background:grey;
  }

  .header{
    input{
      width: 100px;
      padding: 0 5px;
    }
  }

  .edit{
    display: grid;
    grid-template-columns: 1fr 1.25fr;
    grid-gap: 10px;
    margin: 15px 15px 30px 50px;

    .space-value-header {
      display: flex;
      justify-content: space-between;
      span {
        margin: 0;
        border: none;
        padding: 0;
        &.action {
          display: inline-block;
          color: @color-accent;
          font-weight: 500;
          cursor: pointer;
          padding: 0 8px;
          height: 16px;
          // HOver, darken 20%
          &:hover {
            color: darken(@color-accent, 10%);
          }
        }
      }
    }
    .space-value-content {
      margin-top: 8px;
      .item {
        background: #F0F0F0;
        border-radius: 4px;
      }
    }

    span{
      display: inline-block;
      border: 1px solid rgba(60,60,60,.26);
      padding: 4px 8px;
      margin: 0 4px 4px 0;

      &.removing{
        border-color: red;
      }
      .remove{
        color: white;
        cursor: pointer;
        background: red;
        padding: 1px 1px 3px;
        line-height: 14px;
      }

      &.action {
        color: @color-chart-plot;
        font-weight: 500;
        cursor: pointer;
        padding: 0 8px;
        height: 16px;
      }
    }

    .border{
      border: 1px solid rgba(60,60,60,.26);
      padding: 8px;
    }
    .comment{
      white-space: pre-wrap;
      min-height: 18px;
    }
  }
}
</style>

<script>
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/hu';
import m from '/model';
import Spinner from './spinner';

import datetimepicker from '../stats/datetimepicker.vue';

const validators = {
  name: i=>(i.length > 0),
  start: i=>(typeof i !== 'undefined'),
  end: i=>(typeof i !== 'undefined'),
  parking_spaces: i=>(i.length > 0)
};

function createAbortedClosure(ld){
  return {
    status: 'A', // Abort now
    parking_spaces: ld.parking_spaces,
    name: ld.name,
    start: ld.start,
    end: ld.end,
    deactivate_sensors: ld.deactivate_sensors,
    comment: ld.comment,
  }
}

export default {
  name: 'closure',
  components: {datetimepicker, Spinner},
  props: {
    selectedpss: Array,
    ld: Object,
    adding: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      working: false,
      edit: this.adding || false,
      isShowParkingSpaces: false,
      ps_removing: false,
      allow: m.user.allow,
      ps_removed: [],
      ps_original: [],
      newvals: {
        name: '',
        start: undefined,
        end: undefined,
        parking_spaces: [],
        comment: ''
      },
      errors: {
        name: false,
        start: false,
        end: false,
        parking_spaces: false
      }
    };
  },
  destroyed() {
    if (this.edit && this.anyEdit) {
      m.closures.setEditMode(false);
    }
  },
  computed: {
    anyEdit(){
      return m.closures.editMode;
    },
    canDelete: function() {
      return this.ld.status == 'U' && !this.edit;
    },
    canEdit: function() {
      return this.ld.status == 'U' && !this.edit;
    },
    canAbort: function() {
      return this.ld.status == 'I' && !this.edit && !this.ps_removing;
    },
    isAborted: function() {
      return this.ld.status == 'A' && !this.edit;
    }
  },
  methods: {
    dtfmt(v){
      var s = moment(v);
      s.locale(this.$i18n.locale)
      return s.format('LLL');
    },
    deleteItem(){
      if (confirm(this.$t('edit.confirm_delete'))) {
        m.closures.deleteLD(this.ld);
      }
    },
    abortItem(){
      if (confirm(this.$t('settings.closure.confirm_stop'))) {
        m.closures.updateLD(this.ld, createAbortedClosure(this.ld));
      }
    },

    startRemoving(){
      this.ps_removing = true;
      this.ps_original = this.ld.parking_spaces;
    },
    removePs(idToRemove){
      if (this.ld.parking_spaces.length > 1) {
        this.ld.parking_spaces = this.ld.parking_spaces.filter(psid=>psid!=idToRemove);
        this.ps_removed.push(idToRemove);
      }
    },
    cancelRemoving(){
      this.ld.parking_spaces = this.ps_original;
      this.ps_removing = false;
      this.ps_original = [];
      this.ps_removed = [];
    },
    finishRemoving(){
      if (this.ps_removed.length > 0) {
        // Create aborted one
        let aborted = createAbortedClosure(this.ld);
        delete aborted.id;
        aborted.name += (' ' + 'DEL-' + (new Date()).toISOString().replace(/[:Text-]/g, '').substr(4, 10))
        aborted.parking_spaces = this.ps_removed;
        m.closures.addLD(aborted);
        // Save updated one
        m.closures.updateLD(this.ld, this.ld);
      }

      this.ps_removing = false;
      this.ps_original = [];
      this.ps_removed = [];
    },

    startEditing(){
      m.selection.clear();
      if(!this.adding){
        this.ld.parking_spaces.forEach(ps=>{
          m.selection.selectPS(ps);
        });
        this.newvals.name = this.ld.name;
        this.newvals.start = this.ld.start;
        this.newvals.end = this.ld.end;
        this.newvals.deactivate_sensors = this.ld.deactivate_sensors;
        this.newvals.comment = this.ld.comment;
        this.edit = true;
        m.closures.setEditMode(true);
      }
    },
    cancelEditing(){
      this.$emit('finished');
      this.edit = this.adding;
      m.closures.setEditMode(false);
    },
    finishEditing: async function(){
      this.newvals.parking_spaces = this.selectedpss;

      var err = false;
      for(var k in validators){
        if(!validators[k](this.newvals[k])){
          this.errors[k] = true;
          err = true;
        }else{
          this.errors[k] = false;
        }
      }
      if(err) return;

      this.working = true;
      let res;
      if(!this.adding){
        res = await m.closures.updateLD(this.ld, this.newvals);
      }else{
        res = await m.closures.addLD(this.newvals);
      }
      this.working = false;
      if (res) {
        this.$emit('finished');
        this.edit = this.adding;
        m.closures.setEditMode(false);
      }
    },
    toggleParkingSpaces() {
      this.isShowParkingSpaces = !this.isShowParkingSpaces;
    },
    getSetLength() {
      return this.edit
        ? this.selectedpss.length
        : (this.ld && this.ld.parking_spaces ? this.ld.parking_spaces.length : 0);
    },
    getSetName() {
      return this.edit
        ? this.$t('common.selected')
        : this.$t('common.total_parking_spaces');
    }
  }
};
</script>
